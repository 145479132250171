// PAGES
import homeAuth from './help/en/pages/home-auth';
import home from './help/en/pages/home';
import howItWorks from './help/en/pages/how-it-works';
import ispPartners from './help/en/pages/isp-partners';
import partnerStreamTV from './help/en/pages/partner-stream-tv';
import partnerTV from './help/en/pages/partner-tv';
import streamingServicesPartners from './help/en/pages/streaming-services-partners';
import about from './help/en/pages/about';
import start from './help/en/pages/start';
import channels from './help/en/pages/channels';
import contactUs from './help/en/pages/contactUs';
import results from './help/en/pages/results';
import appDetails from './help/en/pages/app-details';
import planDetails from './help/en/pages/plan-details';
import streamingMarketplace from './help/en/pages/streaming-marketplace';
import movieDetails from './help/en/pages/movie-details';
import showDetails from './help/en/pages/show-details';
import watchlists from './help/en/pages/watchlists';
import watchList from './help/en/pages/watch-list';
import watchListShared from './help/en/pages/watch-list-shared';
import starry from './help/en/pages/starry';
import internetOffers from './help/en/pages/internet-offers';
import WPPost from './help/en/pages/wp-post';
import internetOption from './help/en/pages/internet-option';
import watchNext from './help/en/pages/watch-next';
import watchNextEdit from './help/en/pages/watch-next-edit';
import watchNextResults from './help/en/pages/watch-next-results';
import profileDetails from './help/en/pages/profile-details';
import profileMyApps from './help/en/pages/profile-my-apps';
import showsAndMovies from './help/en/pages/shows-and-movies';
import subscription from './help/en/pages/subscription';
import promotion from './help/en/pages/promotion';
import businessApps from './help/en/pages/business-apps';
import streamingChoiceLanding from './help/en/pages/streaming-choice-landing';
import streamingRewards from './help/en/pages/streaming-rewards';
import streamingRewardsClaim from './help/en/pages/streaming-rewards-claim';
import streamingRewardsFunds from './help/en/pages/streaming-rewards-funds';
import streamingChoice from './help/en/pages/streaming-choice';
import channelLineup from './help/en/pages/channel-lineup';
import fbsp from './help/en/pages/fbsp';
import freeTv from './help/en/pages/free-tv';
import mobileApp from './help/en/pages/mobile-app';

// COMPONENTS
import authDrawer from './help/en/components/auth-drawer';
import onboarding from './help/en/components/onboarding';
import swimlanes from './help/en/components/swimlanes';

export default Object.freeze({
  translations: {
    // Common
    common: {
      app: 'App',
      apps: 'Apps',
      and: 'and',
      apply: 'apply',
      allDevices: 'All devices',
      myBundleTv: 'MyBundle',
      aboutMyBundle: 'About MyBundle',
      streamingApps: 'Streaming Apps',
      forPartners: 'For Partners',
      featuresItem: 'Features',
      month: 'month',
      paid: 'Paid',
      free: 'Free',
      copyLink: 'Copy Link',
      clickToCopy: 'Click to copy',
      linkCopied: 'Link copied',
      channel: 'Channel',
      channelList: 'Channel List',
      channels: 'Channels',
      with: 'With:',
      dvr: 'DVR',
      addOn: 'Add-on',
      addOns: 'Add-ons',
      have: 'Have',
      missing: 'Missing',
      signUpFor: 'Sign up for',
      price: 'Price',
      total: 'Total',
      normally: 'Normally',
      today: 'Today',
      deal: 'Deal',
      live: 'Live',
      liveTV: 'Live TV',
      liveOn: 'Live on {{name}}',
      watchItLive: 'Watch it Live',
      freeTrial: 'Free Trial',
      noSearchResults: 'No results found, try another search!',
      costAdditional: '+${{cost}}',
      cost: '${{cost}}',
      costMonth: '${{cost}}/month',
      costMo: '${{cost}}/mo',
      costMonthWithTag: '<strong>${{cost}}</strong>/month',
      countAvailable: '{{count}} available',
      all: 'All',
      categories: 'Categories',
      seasonNumber: 'Season {{number}}',
      seasonsCount: '{{number}} season',
      seasonsCount_plural: '{{number}} seasons',
      episodesCount: '{{number}} episode',
      episodesCount_plural: '{{number}} episodes',
      orCall: 'Or call',
      purchase: 'Purchase',
      subscribe: 'Subscribe',
      subscribed: 'Subscribed',
      rentPurchase: 'Rent/Purchase',
      or: 'or',
      for: 'for',
      chooseGenre: 'Choose a Genre',
      seasons: 'Seasons',
      season: 'Season',
      settings: 'Settings',
      year: 'Year',
      availableOn: 'Available on',
      watchNow: 'Watch Now',
      genresLabel: 'Genres',
      genreLabel: 'Genre',
      here: 'here',
      vote: 'Vote',
      votes: 'Votes',
      anonymous: 'Anonymous',
      recommendedBy: 'Recommended by: {{name}}',
      you: 'You',
      noVotes: 'No votes',
      andMore: 'and {{count}} more',
      plusCountMore: '+{{count}} more',
      upTo: 'up to',
      recommendedPackage: 'Recommended Package',
      planDetails: 'Plan details',
      durationMonths: 'for first {{duration}} months',
      forFirstMonth: 'for first month',
      backTo: 'Back to',
      readMore: 'read more',
      selectedShows: 'Selected shows',
      bill: 'Bill',
      totalCost: 'Total cost',
      call: 'Call',
      availableRentPurchase1: 'Available for rent or',
      availableRentPurchase2: 'purchase on',
      trackServices: 'For tracking services you need to be Signed up',
      optional: 'Optional',
      recommendationsBy: 'Recommendations by',
      filters: 'Filters',
      trialDaysPeriod: 'Free Trial: {{period}} Days',
      trialFirstDaysPeriod: 'Free Trial: first {{period}} days',
      yes: 'Yes',
      no: 'No',
      totalPrice: 'Total Price',
      liked: 'Liked',
      disliked: 'Disliked',
      film: 'Movie',
      show: 'Show',
      keyFeatures: 'Key Features',
      search: 'Search',
      tvShows: 'TV Shows',
      movies: 'Movies',
      insights: 'Insights',
      jumpTo: 'Jump to',
      jumpToSection: 'Jump to Section',
      includingGenre: 'Including Genre',
      potentialUpgrade: 'Potential Upgrade',
      moreDetails: 'More Details',
      lessDetails: 'Less Details',
      description: 'Description',
      subscription: 'Subscription',
      tax: 'tax',
      termsAndConditions: 'Terms and Conditions',
      location: 'Location',
      jobType: 'Job Type',
      optimalStartDate: 'Optimal Start date',
      smthWentWrong: 'Something went wrong',
      poweredBy: 'Powered by',
      changePassword: 'Change Password',
      onName: 'On {{name}}',
      showAllApps: 'Show all Apps',
      showAllChannels: 'Show all Channels',
      showAllMovies: 'Show all Movies',
      showAllTVShows: 'Show all TV Shows',
      seeEntityInString: 'See {{entity}} in: {{string}}',
      seeEntityInCategory: 'See {{entity}} in <b>{{string}}</b> Category',
      countAdded: '{{count}} Added',
      plans: 'Plans',
      includedIn: 'included in {{name}}',
      updatePaymentMethod: 'Update Payment Method',
      inPartnerShipWith: 'In partnership with',
      nextPayment: 'Next payment',
      status: 'Status',
      contentSelected: 'Selected',
      viewSelectedList: 'View Selected List',
      help: 'Help',
      similarContent: 'Similar content',
      replacements: 'Replacements',
      noRecommendation: 'No Recommendation',
      card: 'Card',
      us_bank_account: 'US Bank Account',
      virtual_card: 'Virtual card',
      promotions: 'Promotions',
      limitedTimeOffer: 'Limited Time Offer',
      device: 'Device',
      plan: 'Plan',
      comingSoon: 'Coming soon',
      available: 'Available',
      expired: 'Expired',
      expiresOn: 'Expires on: {{date}}',
      default: 'Default',
      addNewPaymentMethod: 'Add a New Payment Method',
      faqs: 'FAQs',
      step: 'Step',
      getMyBundleApp: 'Get MyBundle App',
      role: 'Role',
      actorsDirectors: 'Actors & Directors',
      actor: 'Actor',
      director: 'Director',
      upcoming: 'Upcoming',
      hr: '{{duration}} hr',
      min: '{{duration}} min',
      mins: '{{duration}} mins',
      minutes: '{{duration}} minutes',
      type: 'Type',
      newEpisode: 'New Episode',
      newEpisodes: 'New Episodes',
      service: 'Service',
      subscriptionPlan: 'Subscription Plan',
      monthlyFee: 'Monthly Fee',
      paymentType: {
        subscription: 'subscription',
        single_charge: 'single charge',
        top_up: 'top up',
        refund: 'refund',
      },
      features: {
        title: "Plus, you'll get:",
        curiosityStreamTitle: 'Curiosity Stream features',
        features1: {
          title: 'Personalized Recommendations',
          desc: 'Receive personalized TV Show & Movie recommendations across your apps.',
        },
        features2: {
          title: 'Search Where to Watch',
          desc: 'Search for Movie or TV show titles to discover where to watch them.',
        },
        features3: {
          title: 'Manage your Streaming Costs',
          desc:
            'Manage your streaming costs, access special deals, and find apps that match your interests.',
        },
        features4: {
          title: 'Create Watchlists',
          desc: "Plan what you want to watch & track what you've seen in your custom watchlists.",
        },
        features5: {
          title: 'Thousands of documentaries',
        },
        features6: {
          title: 'Award-winning exclusives & originals',
        },
        features7: {
          title: 'Watch anytime, anywhere',
        },
      },
      genres: {
        action: 'Action',
        cartoon: 'Cartoon',
        comedy: 'Comedy',
        crime: 'Crime',
        drama: 'Drama',
        family: 'Family',
        food: 'Food',
        horror: 'Horror',
        news: 'News',
        movies: 'Movies',
        other: 'Other',
        reality: 'Reality',
        religion: 'Religion',
        spanish: 'Spanish',
        sports: 'Sports',
      },
      menu: {
        explore: 'Marketplace',
        howItWorks: 'How it Works',
        about: 'About',
        aboutUs: 'About Us',
        ispPartnerWithUs: 'Broadband Partners ',
        streamingPartnerWithUs: 'Streaming Service Partners',
        faq: 'FAQ',
        insights: 'Insights',
        news: 'News',
        careers: 'Careers',
        home: 'Home',
        watchNext: 'Find A New Show',
        startHere: 'Start Here',
        findMyBundle: 'Find My Bundle',
        exploreTutorial: 'Explore Tutorial',
        bundle: 'Bundle',
        internetOffers: 'Internet Offers',
        profile: 'Profile',
        profileDetails: 'Profile Details',
        myResults: 'Find My Bundle Results',
        myList: 'My List',
        watchLists: 'Watchlists',
        watchList: 'Watchlist',
        findShowMovie: 'Find a Show or Movie',
        tvShowsAndMovies: 'Shows & Movies',
        discoverShowsAndMovies: 'Discover Shows & Movies',
        myApps: 'My Apps',
        watchNextResults: 'Watch Next Results',
        appLists: 'App Lists',
        start: 'Find My Bundle',
        results: 'Find My Bundle',
        internet: 'Internet',
        bingeResults: 'Ask My Friend List',
        createBingeResults: 'Create your Ask My Friend List',
        streamingRewards: 'Streaming Rewards',
        streamingChoice: 'Streaming Choice',
        paymentHistory: 'Payment History',
        friendsRecommendations: 'Friends Recommendations',
        contactUs: 'Contact Us',
        freeLiveTV: 'Free Live TV',
        freeTV: 'Free TV',
        liveTVApp: 'Live TV App',
        mobileApp: 'Mobile App',
        blackFriday: 'Black Friday',
        helpCenter: 'Help Center',
        ourBlog: 'Our Blog',
        privacyPolicy: 'Privacy Policy',
        termsAndConditions: 'Terms & Conditions',
        broadbandProviders: 'Broadband Providers',
        streamingServices: 'Streaming Services',
        nflSchedule: 'NFL Schedule',
        more: 'More',
      },
      share: {
        twitter: 'I just got my personalized streaming tv results on @mybundletv. Get yours here!',
        emailSubject: 'Get%20rid%20of%20cable',
        emailBody:
          "Hey%2C%0D%0A%0D%0AI'm%20finally%20ready%20to%20get%20rid%20of%20my%20cable%20TV%20and%20start%20saving%20are%20you%3F%20MyBundle.TV%20made%20it%20simple%20for%20me%20to%20find%20the%20best%20Live%20TV%20option%20based%20on%20the%20actual%20channels%20and%20features%20I%20needed.%20It%20only%20takes%20a%20few%20minute%20so%20why%20not%20give%20it%20a%20try%20and%20see%20how%20you%20can%20save!%0D%0A%0D%0Ahttps%3A%2F%2Fmybundle.tv%2Fshare%0D%0A%0D%0A",
      },
      movie: {
        year: 'Year',
        yearRange: 'Year range',
        rated: 'Rating',
        imdb: 'IMDB',
        length: 'Length',
        genres: 'Genres',
        status: 'Status',
        current: 'Current',
        runtime: 'Runtime',
      },
      runtimes: {
        other: 'Other',
        halfHour: 'Half hour',
        hour: 'Hour',
      },
      sourceTypes: {
        subscription: 'Subscription',
        rent: 'Rent',
        buy: 'Buy',
        youHaveIt: 'You have it!',
      },
      contentType: {
        movie: 'Movie',
        show: 'TV Show',
      },
      contentStatus: {
        watched: 'Watched',
        watchingNow: 'Watching Now',
        notInterested: 'Not interested',
      },
    },

    // Tooltip
    tooltip: {
      TVShow: 'TV Show',
      movie: 'Movie',
      canBePurchased: 'This App can be purchased and Managed through your MyBundle Account',
      channelIncludedInYourRegion:
        '{{name}} is available in {{percent}}% of your area. Please provide an address to check channel availability',
    },

    // Buttons
    buttons: {
      manage: 'Manage',
      view: 'View details',
      seeDetails: 'See Details',
      more: 'More',
      seeMore: 'See more',
      loadMore: 'Load more',
      close: 'Close',
      createPassword: 'Create Password',
      claim: 'Claim',
      dismiss: 'Dismiss',
      goBack: 'Go Back',
      goToHomePage: 'Go to Home page',
      goToMyApp: 'Go to My App',
      goToMarketplace: 'Go to Marketplace',
      goToShowsAndMovies: 'Go to TV Shows & Movies',
      gotIt: 'Got it!',
      startHere: 'Start Here',
      backToMyApps: 'Back to My Apps',
      backToHomePage: 'Back to Home Page',
      backToHomePartner: 'Back to {{partner_name}}',
      backToMarketplace: 'Back To Marketplace',
      continueRegistration: 'Continue Registration',
      next: 'Next',
      previous: 'Previous',
      yes: 'Yes',
      yesCancel: 'Yes, cancel',
      no: 'No',
      termsOfService: 'Terms of Service',
      privacyPolicy: 'Privacy Policy',
      seeResults: 'See Results',
      submit: 'Submit',
      addToBundle: 'Add to Bundle',
      addedBundle: 'Added Bundle',
      remove: 'Remove',
      removeApp: 'Remove App',
      login: 'Login',
      bundleCheckout: 'Bundle Checkout',
      visitSite: 'Visit Site',
      subscribe: 'Subscribe',
      unsubscribe: 'Unsubscribe',
      notifyMe: 'Notify\u00A0Me',
      subscriptionCancel: 'Cancel Subscription',
      subscriptionRenew: 'Renew Subscription',
      apply: 'Apply',
      applyNow: 'Apply Now',
      reset: 'Reset',
      startOver: 'Start over',
      backToResults: 'My results',
      edit: 'Edit',
      editChannels: 'Edit channels',
      editPrice: 'Edit price',
      editPlan: 'Edit plan',
      exploreHere: 'Explore Here',
      exploreMarketplace: 'Explore Marketplace',
      homepage: 'homepage',
      getMyResults: 'Get my results',
      showMore: 'Show more',
      showLess: 'Show less',
      send: 'Send',
      streamingMarketplace: 'Streaming Marketplace',
      learnMore: 'Learn More',
      buyNow: 'Buy Now',
      get: 'Get',
      discoverApps: 'Discover Apps',
      exit: 'Exit',
      unselectAll: 'Unselect all',
      viewChannels: 'View Channels',
      continue: 'Continue',
      logout: 'logout',
      logOut: 'Log Out',
      cancel: 'Cancel',
      clickHere: 'Click Here',
      findMyBundle: 'Find My Bundle',
      internetOfferForYou: 'Internet Offer For You',
      findInternetOffers: 'Find Internet Offers',
      startNow: 'Start Now',
      backToResutls: 'Back To Results',
      liveTVResults: 'Live TV Results',
      createMyBundle: 'Create My Bundle',
      here: 'here',
      watchTrailer: 'Watch Trailer',
      call: 'Call {{phone}}',
      getThisOffer: 'Get This Offer',
      takeTheSurvey: 'Take The Survey',
      tryAgain: 'Try Again',
      backToMyResults: 'Back To My Results',
      signUpNow: 'Sign Up Now',
      changeAddress: 'Change Address',
      viewMore: 'View More',
      viewLess: 'View Less',
      viewMoreChannels: 'View More Channels',
      viewMoreApps: 'View More Apps',
      generate: 'Generate',
      watched: 'Watched',
      showsHaveSeen: 'Shows you have seen',
      moviesHaveSeen: 'Movies you have seen',
      showsThatWatching: "Shows That I'm watching",
      notInterested: 'Not interested',
      NotInterested: 'Not Interested',
      seen: 'Seen',
      getStarted: 'Get Started',
      startAgain: 'Start Again',
      voteAnonymously: 'Vote Anonymously',
      recommendAnonymously: 'Recommend Anonymously',
      recommendAnotherShow: 'Recommend Another TV Show',
      tryyourself: 'Try It Yourself',
      showlist: 'Show List',
      internet: 'Internet',
      seeMyRecommendations: 'See My Recommendations',
      refreshRecommendations: 'Refresh Recommendations',
      keep: 'Keep',
      save: 'Save',
      registration: 'Sign Up',
      forgotPassword: 'Forgot password?',
      inWatchlist: 'In Watchlist',
      continueWatching: 'Continue watching',
      addToWatchlist: 'Add To Watchlist',
      addedToWatchlist: 'Added To Watchlist',
      removeFromWatchlist: 'Remove from Watchlist',
      removeFromWatched: 'Remove from Watched',
      removeFromWatchingNow: 'Remove from Watching Now',
      removeFromNotInterested: 'Remove from Not Interested',
      removeFromMyApps: 'Remove from My Apps',
      watchingNow: 'Watching Now',
      have: 'Have',
      skip: 'Skip',
      details: 'Details',
      selectMyApps: 'Select My Apps',
      add: 'Add',
      addApp: 'Add App',
      addApps: 'Add Apps',
      doThisLater: 'Do this later',
      findSomethingToWatch: 'Find Something to Watch',
      addProfileInformation: 'Add Profile Information',
      createAccount: 'Create account',
      setUpAccount: 'Set Up Account',
      setUpAccountWithName: 'Set Up {{name}} Account',
      loginToAccount: 'Login to your account',
      claimNow: 'Claim Now',
      claimReward: 'Claim Reward',
      claimed: 'Claimed',
      switchReward: 'Switch Reward',
      donate: 'Donate',
      findMore: 'Find more',
      hide: 'Hide',
      hidden: 'Hidden',
      back: 'Back',
      watchNow: 'Watch now',
      watchWithName: 'Watch {{name}}',
      watchList: {
        wantToWatch: 'Want to Watch',
        haveWatched: 'Have Watched',
        currentlyWatching: 'Continue Watching',
      },
      viewResults: 'View Results',
      readMore: 'Read more',
      resetAndSearch: 'Reset and search',
      changeCard: 'Change Card',
      confirm: 'Confirm',
      findYourOffer: 'Find Your Offer',
      addToMyApps: 'Add to My Apps',
      inMyApps: 'In My Apps',
      seeAll: 'See all',
      search: 'Search',
      resetFilters: 'Reset Filters',
      advancedSearch: 'Advanced Search',
      broadbandPartners: 'Broadband Partners',
      streamingServices: 'Streaming Services',
      email: 'Email',
      scheduleMeeting: 'Schedule Meeting',
      noThanks: 'No, thanks',
      signUp: 'Sign up',
      findYourInternet: 'Find Your Internet',
      addToVoteList: 'Add to Vote list',
      inVoteList: 'In Vote list',
      chooseApps: 'Choose Apps',
      letUsKnow: "Something's wrong? Let us know.",
      report: 'Report',
      createYourProfile: 'Create your profile',
      claimPromo: 'Get Your Promo Code!',
      howToUseCode: 'How to use a code?',
      findWhatToWatch: 'Find what to watch',
      offerDetails: 'Offer Details',
      goToSomewhere: 'Go to {{name}}',
      saveCardToChrome: 'Save Card to Chrome',
      manageAccount: 'Manage Account',
      updatePassword: 'Update password',
      downloadPdf: 'Download PDF',
      emailMyBundle: 'Email MyBundle',
      receiveDeck: 'Receive Deck',
      confirmSubscriptionPlan: 'Confirm Subscription Plan',
      seeChannels: 'See Channels',
      addFunds: 'Add Funds',
      getRecommendations: 'Get Recommendation',
      createAProfile: 'Create a Profile',
      checkAvailability: 'Check Availability',
      watchFree: 'Watch Free',
      openPositions: 'Open Positions',
      addToList: 'Add to List',
      submitVote: 'Submit vote',
      saveAndShare: 'Save & Share',
      createMyOwn: 'Create My Own',
      selectChannels: 'Select Channels',
      proceedToPayment: 'Proceed to Payment',
      enterAddress: 'Add Address',
      getSlingNow: 'Get Sling Now',
      select: 'Select',
      addPayment: 'Add Payment',
      activateCredential: 'Activate Credential',
      letsGetStarted: "Let's get started",
      viewInstructions: 'View Instructions',
      addToMyWatchlist: 'Add to my watchlist',
      textMe: 'Text Me',
      downloadApp: 'Download App',
      redeemOffer: 'Redeem Offer',
      contactUs: 'Contact Us',
      linkAccount: 'Link Account',
      great: 'Great',
      done: 'Done',
      redeemReward: 'Redeem My Reward',
      createNewList: 'Create New List',
      createNewWatchlist: 'Create New Watchlist',
      rename: 'Rename',
      yesDelete: 'yes, delete',
      noGoBack: 'No, go back',
      create: 'Create',
      share: 'Share',
      removeItem: 'Remove Item',
      getTheApp: 'Get The App',
      clearAll: 'Clear All',
      iDontKnow: 'I don’t Know',
      recommendMore: 'Recommend More',
      watchLive: 'Watch Live',
      claimPromoCode: 'Claim Promo Code',
      setUpLater: 'Set Up Later',
      useMyCreditsNow: 'Use My Credits Now',
    },

    // Inputs
    inputs: {
      firstName: 'First Name',
      lastName: 'Last Name',
      username: 'Username',
      usernameHelp: 'We generated a username from your email, feel free to update it',
      yourAddress: 'Your Address',
      zipcode: 'ZIP Code',
      zipcodeEnter: 'Enter Your Zip Code*',
      enterZipCode: 'Enter Zip Code',
      other: 'Other',
      findChannels: 'Search by show or channel',
      email: 'E-mail',
      emailPlaceholder: 'Enter your email address',
      emailAddress: 'E-mail Address',
      yourMailPlaceholder: 'Your email',
      devices: 'Devices',
      similarChannels: 'Similar Channels',
      allGenres: 'All Genres',
      sortBy: 'Sort By',
      findAppPlaceholder: 'Find app, description, genre...',
      genresPlaceholder: 'Genres',
      similarChannelsPlaceholder: 'Similar channels',
      selectAddons: 'Select Add-ons',
      title: 'Title',
      details: 'Details',
      fullName: 'Full Name',
      name: 'Name',
      addressPlaceholder: 'Enter your address',
      findShowsPlaceholder: 'Search for TV Shows...',
      findSuggestionsPlaceholder: 'Search for TV Show, Movie, App, Genre...',
      password: 'Password',
      newPassword: 'New password',
      oldPassword: 'Old password',
      searchApps: 'Search by apps',
      price: 'Price',
      creditCard: 'Credit Card',
      creditCardNum: 'Credit Card Number',
      date: 'Date',
      receipt: 'Receipt',
      paymentDollars: 'Payment ($)',
      expDate_short: 'Exp. Date',
      expDate: 'Expiration Date',
      nextBillingDate: 'Next Billing Date',
      cvc: 'CVC',
      cvc_info:
        'CVC is the 3 digits number located on the back of your credit/debit card on the right side of the white signature strip',
      comment: 'Comment',
      search: 'Search for TV Show, Movie, App, Genre or Keyword',
      enterZipGetFullChannelList: 'Enter your zip code to get full channel list',
      showingChannelsAvailableIn: 'Showing channels available in',
      searchBy: 'Search by {{name}}',
      searchByChannels: 'Search by channels',
      addAmount: 'Add amount',
      enterName: 'Enter Name',
    },

    // Validation
    validation: {
      emailRequired: 'Email is required!',
      emailNotValid: 'Enter a valid email address',
      zipCodeRequired: 'ZIP Code is required!',
      minZipCodeLength: 'ZIP Code has to be at least {{length}} characters long!',
      maxZipCodeLength: "ZIP Code can't be at more than {{length}}!",
      shouldBeNumeric: 'Should be a number!',
      ratingRequired: 'Rating is required!',
      titleRequired: 'Title is required!',
      detailsRequired: 'Details is required!',
      fullNameRequired: 'Full Name is required!',
      nameRequired: 'Name is required!',
      firstNameRequired: 'First name is required!',
      lastNameRequired: 'Last name is required!',
      usernameRequired: 'Username is required!',
      passwordRequired: 'Password is required!',
      passwordLength: 'Password has to be at least {{length}} characters long!',
      passwordLengthMax: 'Password cannot be longer than {{length}} characters!',
      passwordLetter: 'Password has to be at least one character!',
      passwordSpec: 'Password has to be at least one special character or number!',
      oldPasswordRequired: 'Old password is required!',
      newPasswordRequired: 'New password is required!',
      newPasswordMatch: "New password can't be the same as old password!",
      emailsAlreadyTaken: 'Email already has been taken',
      userNameAlreadyTaken: 'Username already has been taken',
      priceRequired: 'Price is required!',
      priceMaxLength: "The price can't be greater than {{max_price}}!",
      priceMustBeSmaller: 'The price should be smaller than {{max_price}}!',
      priceNotValid: 'The price not valid!',
      cardNumRequired: 'Card number is required',
      cardExpRequired: 'Card exp is required',
      cardCvcRequired: 'Card cvc is required',
      commentRequired: 'Comment is required',
      maxCommentLength: "Comment can't be at more than {{length}}!",
      maxNameLength: "Name can't be at more than {{length}}!",
      amountRequired: 'Amount is required',
      amountMin: 'The minimum amount is ${min}',
      amountMax: 'The maximum amount is ${max}',
      saveCardRequired: 'You need to save card to MyBundle for future payments',
    },

    // Notification
    notification: {
      successfullyAddedEmail: 'Email sent!',
      successfullyUnsubscribedEmail: 'Your email has been successfully unsubscribed.',
      successfullySentReview: 'Your review has been successfully sent.',
      successfullySubscribedEmail: 'Your email has been successfully subscribed.',
      channelAlreadySelected: '{{channelName}} is already selected',
      channelsSuccessfullyAdded: '{{channelName}} was added to selected channels',
      channelsBundleSuccessfullyAdded: 'Channels were added to selected channels',
      waitWhileRecContentLoading: 'Please wait for recommended content to be loaded',
      waitWhileSubscriptionActivating:
        'We are activating your account, please do not leave or refresh the page.',
      waitWhilePaymentProcessing:
        "We are processing payment, please don't refresh or close this screen",
      waitWhileSubscriptionProcessing:
        "We are processing your subscription, please don't refresh or close this screen",
      waitWhileCardAuthorizing:
        "We are authorizing your card, please don't refresh or close this screen",
      showMarkedWatched: 'Saw it already!',
      showMarkedNotInterested: 'Not interested.',
      showAdded: 'Show Added.',
      alreadyRecommended: 'Already Recommended!',
      alreadyAdded: 'Already Added!',
      serialHaveKept: "You have kept '{{name}}' on your list",
      autoLogin: 'We have recognized you as a registered user and logged you in',
      resetPassSuccess: 'A password recovery email has been sent to your email address.',
      resetPassUpdateSuccess: 'Password was successfully changed',
      profileUpdateSuccess: 'Profile data was successfully updated.',
      successfullyAddedToList: 'Successfully added to list',
      successfullyAddedToWatchLists: 'Successfully added to watchlists',
      successfullyAddedTo: 'Added to {{name}}',
      successfullyRemovedFromList: 'Successfully removed from list',
      successfullyRemovedFrom: 'Removed from {{name}}',
      successfullyRemovedFromWatchList: 'Successfully removed from Watchlist',
      appAlreadySelected: '{{appName}} is already selected',
      appSuccessfullyAdded: '{{appName}} was added to selected apps',
      successfullyCompleteOnboarding: 'Successfully completed onboarding',
      successfullyChangedOrder: 'Successfully changed order',
      successfullyUpdatedPrice: 'Price was successfully updated',
      successfullySentSubscribedEmail: 'You was successfully subscribed, please verify your email',
      emailExist: 'Sorry, this email already exists',
      infoMissing: 'Check back soon! ',
      notRegisteredUser:
        'That e-mail is not registered yet. Do you want to create an account using that email?',
      successfullyCopiedPromoCode: 'Promocode was successfully copied to buffer',
      successfullyPurchasedProduct: 'Product has been successfully purchased',
      successfullySentEmail: 'Thanks, someone will be in touch soon!',
      successfullyCanceledNextMthPayment: 'Next monthly payment successfully canceled',
      hCaptchaMissing: 'HCaptcha is not available.',
      encProblem:
        'Looks like we encountered a problem, please contact help@mybundle.tv to continue',
      ipGeoMissing:
        'Looks like your browser is blocking an IP address lookup, we need to know your location to get you the correct channels. Please try a different browser.',
      addedToTheList: '{{name}} added to the list',
      alreadyAddedToTheList: '{{name}} already added to the list',
      loginAsUser: 'You are logged in as {{name}}',
      tokenAlreadyUsed: 'Token already used',
      setDefaultCardSuccess: 'Default payment method was successfully changed',
      removePaymentMethodSuccess:
        "You've successfully removed the payment method ending with {{last4}}",
      addPaymentMethodSuccess: "You've successfully added the new payment method",
      sendMobileNumberSuccess: 'We will send you a link shortly',
      successfullyCopiedWatchList: '{{name}} watchlist successfully copied to your watchlists',
      successfullyRenamedWatchList: 'Watchlist Rename Successfully',
      successfullyDeletedWatchList: 'Watchlist Deleted',
      successfullyDeletedVoteList: 'Recommendation list Deleted',
      successfullyCreatedWatchList: '{{name}} Created',
      successfullyCopied: 'Link Copied to Clipboard',
      wnResultsNotAvailable: 'Recommendation does not exist. Please try to create a new one.',
    },

    // Alerts
    alerts: {
      storageUnavailable:
        'Unfortunately our recommendation tools don’t work with third-party cookies blocked, please try another browser or turn off incognito mode.',
      storageUnavailableV2:
        'Unfortunately our tools don’t work with third-party cookies blocked, please try another browser or turn off incognito mode.',
    },

    // Breadcrumbs
    breadcrumbs: {
      discover: 'Marketplace',
      'internet-offers': 'Internet offers',
      'shows-and-movies': 'TV Shows & Movies',
      search: 'Discover',
    },

    // ** PAGES -----------------------------------------

    // NotFound Page
    notFound: {
      title: 'Oh no! 404 Error. We couldn’t find that page.',
      subtitle:
        'Try searching for more content or return to <a><span>MyBundle’s</span> Home Page.</a>',
      searchPlaceholder:
        "Search for what you're looking for, e.g. movies, TV shows, watchlists, FAQs, and more.",
    },

    // Home Page
    home,

    // partner home page
    partner: {
      welcome: {
        left: {
          subtitle: 'TV Recommendation Tool',
          title: 'Find the best Live TV streaming for you',
          button: 'Find My Bundle',
          imagealt: 'Find the best Live TV streaming for you',
        },
        right: {
          subtitle: 'Discover more streaming',
          title: 'Browse or filter to find new services',
          button: 'Explore Marketplace',
        },
      },
      banner: {
        message: 'Tired of your ridiculous cable bill but not sure where to even begin?',
        button: 'Learn More',
      },

      loaders: {
        default: '',
        questions: '',
      },
      appSection: {
        subtitle:
          'Struggle to discover new movies & shows and where to find them? Level up your experience with the MyBundle App.',
        features: {
          item4: 'Quickly get and give recommendations on what to watch next. ',
        },
      },
    },

    // partner fake home page
    partnerFake: {
      welcome: {
        left: {
          title: 'Find My Bundle',
          paragraph1:
            'Tired of paying for an expensive cable bill with channels you don’t even watch?',
          paragraph2:
            'Answer a few questions and we’ll find the best Live TV streaming service replacement for you.',
          button: 'Find My Bundle',
        },
      },
    },

    // Home Page for authorized users
    homeAuth,

    // Home Page for authorized users
    partnerHomeAuth: {
      welcome: {
        left: {
          subtitle: 'MyBundle Profiles brought to you by {{partner}}',
          title: 'MyBundle simplifies streaming TV while saving you time & money',
          button: 'Create your profile',
        },
      },
      hero: {
        title: 'MyBundle simplifies streaming TV.',
        subtitle: '',
      },
    },

    // partner tv page
    partnerTV,

    // partner streamtv page
    partnerStreamTV,

    // Start Page
    start,

    // Channels Page
    channels,

    // Results Page
    results,

    // Results Page
    resultsCSR: {
      startOverTitle: '',
    },

    // Privacy Policy
    privacyPolicy: {
      title: 'Privacy Policy',
      subtitle: 'Last updated: {{date}}',
      loaders: {
        default: '',
      },
    },

    // Terms and Conditions
    termsAndConditions: {
      title: 'Terms And Conditions',
      subtitle: 'Last updated: {{date}}',
      loaders: {
        default: '',
      },
    },

    // Discover Tutorial
    discoverTutorial: {
      title: 'Streaming Marketplace "How To" guide',
      subtitle:
        'To get the most out of our Streaming Marketplace, you can browse, search for which services have the TV shows and movies you want, or use various filters like Genre and Channel. ',
      tutorial1: 'Search for Shows or Movies',
      tutorial2: 'Filter by Genre',
      tutorial3: 'Find apps similar to TV channels',
      tutorial4: 'Toggle between\nFree, Paid or Both',
    },

    // How It Works
    howItWorks,

    // ISP Partners
    ispPartners,

    // Streaming Services Partners
    streamingServicesPartners,

    // FAQ
    faq: {
      title: 'FAQ',
      subtitle: 'Streaming can be confusing. Here are some of the most popular questions we get:',
      loaders: {
        default: '',
      },
    },

    // Careers
    careers: {
      title: 'MyBundle Careers',
      subtitle: 'Come create a better way to TV!',
      values: {
        title: 'Why MyBundle',
        subtitle: 'Benefits',
      },
      positions: {
        title: 'Open Positions',
      },
      categories: {
        title: 'Roles',
      },
      loaders: {
        default: 'Loading the open positions',
      },
      emptyState: {
        text: 'No open positions',
      },
    },

    // About
    about,

    // Contact Us
    contactUs,

    // Streaming Marketplace
    streamingMarketplace,

    // Streaming Marketplace Discover
    streamingMarketplaceDiscover: {
      title: 'Streaming Apps',
      noResults: 'No apps found, try another search!',
      filters: {
        searchPlaceholder: 'Search by Title',
        genresPlaceholder: 'Filter By Genres',
        channelsPlaceholder: 'Filter Channel',
      },
    },

    // App Details
    appDetails,

    // Plan Details
    planDetails,

    // Movie Details
    movieDetails,

    // Show Details
    showDetails,

    // Watchlists
    watchlists,

    // Watch List
    watchList,

    // Watch List Shared
    watchListShared,

    // Starry
    starry,

    // Insights page
    insightsPage: {
      noPosts: 'No posts posted yet',
      loaders: {
        default: 'Getting the latest insights',
        article: 'Getting the latest insights',
        category: 'Getting the latest insights',
        author: 'Getting the latest insights',
      },
    },

    // News page
    newsPage: {
      title: 'News',
      noPosts: 'No posts posted yet',
      loaders: {
        default: 'Getting the latest news',
        article: 'Getting the latest news',
        category: 'Getting the latest news',
        author: 'Getting the latest news',
      },
    },

    // internetOffers
    internetOffers,

    // WPPost page
    WPPost,

    // WPPost page
    WPCategoryPage: {
      title: 'Category: {{name}}',
    },

    // Internet Option
    internetOption,

    // WatchNext
    watchNext,

    // WatchNext Edit
    watchNextEdit,

    // WatchNext Tutorial
    watchNextTutorial: {
      title: 'Looking for a new show to watch?',
      offer:
        "Who better to ask than your friends. We'll generate a list of shows based on your interests and your friends vote to help you decide!",
    },

    // WatchNext Results
    watchNextResults,

    // Profile - Details
    profileDetails,

    // Profile - Results
    profileResults: {
      title: 'My Results',
      subtitle: '',
      emptyState: {
        title: "You don't have any results yet",
      },
    },

    // Profile - My apps
    profileMyApps,

    // Profile - Watch Next Results
    profileWNResults: {
      title: 'Watch Next Results',
      emptyState: {
        title: "You don't have any results yet",
      },
      card: {
        topTitle: 'Top Voted',
        empty: 'No votes yet',
      },
      banner: {
        title: 'Don’t know what to watch?',
        subtitle:
          'Choose some TV shows based on apps you have and your interests to ask friends to suggest you what you should watch next by voting!',
        btnText: 'Ask My Friends',
      },
    },

    // Shows And Movies
    showsAndMovies,

    // Subscription
    subscription,

    // Promotion
    promotion,

    // Channel details
    channelDetails: {
      zipTitle: 'Check your ZIP code to see if this channel available in your area',
    },

    // Emmy Awards
    emmyAwards: {
      title: 'The 2022 Emmy Guide',
      description:
        'Our guide showcases the nominees from every category and where to stream them. The best part? Create a FREE MyBundle Profile to build a custom watchlist and keep track of the nominated series, movies, and documentaries as you watch them.',
      hero: {
        title: '74th Emmy Award Nominees',
        subtitle: "The television academy's",
        description: "Welcome to MyBundle's interactive 2022 Emmy Watchlist!",
      },
    },

    // Channel details
    searchPage: {
      title: 'Results for “{{search}}”',
      searchPlaceholder: 'Search',
      youCanTryToUseFullSearch: 'or you can try to use a full search for',
      noResults: {
        title: 'Sorry we couldn\'t find any results for "{{search}}"',
        description:
          "Try a different spelling? If that doesn't work our team will see if we can get to the bottom of it.",
      },
    },

    // Business Apps
    businessApps,

    // Streaming choice landing
    streamingChoiceLanding,

    // Streaming rewards
    streamingRewards,

    // Streaming rewards claim
    streamingRewardsClaim,

    // Streaming rewards funds
    streamingRewardsFunds,

    // Streaming choice
    streamingChoice,

    // Channel Lineup
    channelLineup,

    // Best sling package
    fbsp,

    // Streaming Free TV
    freeTv,

    // Mobile App Landing page
    mobileApp,

    // maintenance
    maintenance: {
      title: 'Sorry, our TV Recommendation Tool is temporarily unavailable.',
      subtitle: "We'll have it back up soon. Thanks for your patience.",
    },

    // confirmation isp
    confirmationISP: {
      title: 'Streaming Services',
      subtitle: 'Here some offers just for you',
    },

    // Payment history
    paymentHistory: {
      title: 'Streaming Rewards',
      subtitle: 'Use your Streaming rewards card to pay streaming app subscriptions',
      rewards: {
        title: 'Allocated Streaming Apps',
        date: 'Renew Date',
      },
      history: {
        title: 'Payment History',
        tableHeaders: {
          date: 'Date',
          info: 'Information',
          amount: 'Amount',
        },
      },
    },

    // ** COMPONENTS -----------------------------------------

    // HeaderWizard
    headerWizard: {
      questions: 'Questions',
      channels: 'Channels',
    },

    // Header
    header: {
      profile: {
        title: 'My Profile',
      },
    },

    // Footer
    footer: {
      rights: '© {{year}} MyBundle All rights reserved.',
      subscription: 'Subscribe to our deal alerts',
      termsAndPolicy: 'We care about protecting your data. Here’s our',
      privacyPolicy: 'Privacy Policy',
      termsOfUse: 'Terms of Use',
      contact: 'For any questions or comments email',
    },

    // AppCard
    appCard: {
      content: {
        includedSubscription: 'Included in Subscription',
        rentOrPurchase: 'Rent Or Purchase.\nView Price on App.',
        partialSeason: '* partial season',
        price: {
          rent: '${{price}} to rent',
          purchase: '${{price}} to buy',
          notAvailable: 'Pricing not available.',
        },
      },
      whyIsShowing: 'Because you chose:',
    },

    // ShowCard
    showCard: {
      disabledVote: 'You cannot vote on your own suggestion',
    },

    channelCard: {
      notAvailable: 'Not Available In All Regions',
    },

    searchAutoComplete: {
      placeholder: 'Search for TV Show, Movie, App, Genre...',
      placeholder2: 'Search for App, Genre...',
      noResults: 'No results',
    },

    // Insights
    insights: {
      title: 'Insights',
      subtitle:
        'We’ve compiled a list of everything you need to cut the cord from start to finish.',
    },

    // Auth drawer
    authDrawer,

    // Auth Dialog
    authDialog: {
      title: 'Create a MyBundle Profile',
      description:
        'Finding what to watch just got easier! Create a free profile to browse and get personalized recommendations for TV shows and movies from accross all your streaming services.',
      description_subscription:
        'Sign into your MyBundle profile to continue. Its free and lets you easily track and manage all your apps in one place.',
    },

    // Onboarding
    onboarding,

    // Change price dialog
    changePriceDialog: {
      title: 'Change price for {{name}}',
    },

    // Exit popup dialog
    exitPopupDialog: {
      title: 'Want to browse for yourself?',
      subtitle: 'View our marketplace with over 150 streaming apps',
    },

    // Where to watch
    whereToWatch: {
      title: 'Where to watch',
      unfortunatelyMessage:
        'Unfortunately, "{{content_title}}" is not currently in our streaming database.   We can notify you when it becomes available.',
      unfortunatelyMessageSuccess: 'You will be notified of "{{content_title}}" streaming updates.',
    },

    // Add to watchlist dialog
    addToWatchlistDialog: {
      title: 'Add to Watchlist',
    },

    // SWIMLANES
    swimlanes,

    // Report dialog
    reportDialog: {
      title: 'Report',
      subtitle:
        'Please let us know what went wrong in the comment below and we’ll try to fix it as soon as possible.',
    },

    // Carousels
    selectAppsCarousel: {
      title: 'Tell us what Apps you have',
      subtitle: 'Add your Apps to see personalized TV and movie results.',
    },

    // sign up after selected app
    bannerSaveYourApps: {
      title: 'Save your Apps',
      subtitle:
        'Create a free profile to receive personalized TV Show and Movie recommendations across your apps.',
    },

    advertiserDisclosure: {
      title: 'Advertiser Disclosure',
      disclosure:
        'Many of the products featured here are from our partners who compensate us. However, this does not influence our evaluations or recommendations. Our opinions are our own.',
    },

    // Banner live tv
    bannerLiveTV: {
      title: 'Watch Free Live TV',
      subtitle:
        'Tune in for Hit Movies, TV Shows, News, Sports, Kids and more. All completely free!',
    },

    // Banner tastemade+
    bannerTastemade: {
      title: 'Everything you love, ad-free and on-demand',
      text:
        'A 24/7 channel with award-winning food, travel, and home & design shows from around the world - from groundbreaking originals to classic series and documentaries.',
    },
    bannerMotv: {
      title: "Get Hooked on MyOutdoorTV's World-Class Hunting, Fishing, and Adventure Content.",
      text:
        'Get on-demand live events including Major League Fishing, Live TV Channels like HuntStream®, and fresh from the field: our innovative JustShot™ library.',
      button_text: 'Try MOTV Free Now',
    },

    paymentMethods: {
      confirmRemove: 'Are you sure that you want to remove the payment method {{card_number}}?',
      removeDefaultPaymentMethod:
        'You cannot remove the default payment method. Please first select another one or add a new payment method.',
      setDefaultPaymentMethod: 'Set this as your default payment method.',
    },

    // Social Login Component
    socialLogin: {
      title: 'Or Sign In With',
    },

    // Download app dialog
    downloadAppDialog: {
      title: 'Level Up Your Experience With the MyBundle App',
      description:
        'The MyBundle App has arrived! Download it to easily discover TV shows and movies, instantly receive notifications for recommended content from friends, and enjoy more features.',
    },

    // Episodes
    episodes: {
      title: 'Episodes',
      services: {
        title: 'Available Services ',
      },
    },

    bannerNotClaimedService: {
      title:
        'It looks like you haven’t selected a streaming service yet. Don’t miss out and use your streaming credits now!',
    },

    bannerNotClaimedReward: {
      title:
        'It looks like you haven’t claimed your streaming service yet. Don’t miss out and use your streaming credits now!',
      titleLogout:
        'Don’t forget to claim your reward now and use your streaming credits before they expire!',
    },
  },
});
